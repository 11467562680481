//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        size: {
            type: String,
            default: 'small'
        }
    },
    data: () => ({

    })
}
