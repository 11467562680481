//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    components: { },
    props: {
        row: {
           type: Object,
           default: () => ({})
       },
       model: {
           type: Object,
           default: () => ({})
       },
       config: {
           type: Array,
           default: () => []
       }
    },
    data: () => ({
        dialogVisible: false,
        dialogTitle: '',
        dialogComponent: 'span'
    }),
    computed: {

    },
    methods: {
        dialogOpen(component, title = '') {
            this.dialogComponent = component;
            this.dialogTitle = title;
            this.dialogVisible = true;
        },
        dialogClose() {
            this.dialogVisible = false;
        }
    }
}
