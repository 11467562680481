//
//
//
//
//
//
//
//
//


export default {
    mixins: [],
    components: {
        
    },
    props: {
        value: {
            type: Array,
            default: () => ([])
        },
        action:{
            type:String,
            default:''
        },
        className:{
            type:String,
            default:''
        },

        search: {
            type: Object,
            default: () => ({
                pageindex:1,
                sortname:'',
                sortorder:'desc',
                pagesize:10,
            })
        },
    },
    data() {
        return {
            bottonText:'向下滚动加载更多',
            pageindexMax:1,
        };
    },
    computed: {
        newSearch:{
            get(){
                return this.search;
            },
            set(e){
                this.newSearch = e;
            }
        },
        model:{
            get(){
                return this.value; 
            },
            set(e){
                this.$emit('input',e)
            },

        }
    },
    watch: {},
    methods: {

        getList:function(){
            let that = this;
            this.$get(this.action,this.newSearch,function(data){
                that.pageindexMax = Math.ceil(data.total / this.newSearch.pagesize)
                that.model = [...that.model, ...data.rows];
            })
        },

        handleScroll() {
            var databottom = document.querySelector('.'+ this.className);
            var seeHeight = document.documentElement.clientHeight; //当前页面可见区域高度
            var toTop = databottom.getBoundingClientRect().top; //距离顶部高度
            if (toTop <= seeHeight) {
                this.newSearch.pageindex = this.newSearch.pageindex + 1;
                if (this.newSearch.pageindex <= this.pageindexMax) {
                    this.getList();
                } else {
                    this.bottonText = '~已经到底了~';
                }
            }
        },

        throttle(fn, time) {
            let timer = null
            time = time || 1000
            return function(...args) {
                if (timer) {
                    return
                }
                const _this = this
                timer = setTimeout(() => {
                    timer = null
                }, time)
                fn.apply(_this, args)
            }
        }
    },
    mounted() {
        this.getList();
    },
    beforeMount() {
        window.addEventListener('wheel', this.throttle(this.handleScroll,360));
    },
    beforeDestroy(){
        window.removeEventListener('wheel', this.handleScroll);
    }
};
