//
//
//
//
//
//
//
//

export default {
    props: {
        size: {
            type: String,
            default: 'mini'
        },
        icon: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: ''
        }
    },
    data: () => ({

    })
}
