//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        value: {
            type: Array,
            default: function(){
                return [];
            }
        },
        
        dataList:{
            type: Array,
            default: function(){
                return [];
            }
        },

        ptType:{
            type: String,
            default: '',
        }
 
    },
    data: () => ({
        // groupList:[]
    }),
    
    computed:{
        groupList:{
            get(){
                let list =[];

                if(this.value.length>0){
                    this.value.forEach((item) =>{
                        list.push(item.sourceCode)
                    })
                }

                return list 
            },
            set(val){
                let list =[];

                if(val.length>0){
                    val.forEach((item) =>{
                        list.push(this.filterStatus(this.dataList,item,'sourceCode'));
                    })
                }
            
                this.$emit('input',list)
            }
        }
    },
    watch:{

    },
    methods:{
        checkboxChange(){

        },
        //过滤数组
        filterStatus:function(arr,val,keyWord){
            let newarr =  arr.filter(function(item){
                return item[keyWord] == val ;
            })
            return newarr[0]
        },
    }
}
