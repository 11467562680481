//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import storage from '@js/storage.js'
export default {
    props: {
        value: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ''
        },
        url: {
            type: String,
            default: ''
        },
        resulttype: {},
        isall: {
            type: Boolean,
            default: false
        },
        alltext: {
            type: String,
            default: ''
        },
        size: {},
        changeOnSelect: {
            type: Boolean,
            default: true
        },
        expandTrigger:{
            type: String,
            default: 'hover'
        },
        props: {
            type: Object,
            default: function() {
                return {
                    value: 'citycode',
                    label: 'cityname',
                    children: 'children',
                    disabled: 'disabled',
                    expandTrigger: this.expandTrigger,
                    checkStrictly: this.inAttr(this.changeOnSelect),
                }
            }
        },
        '2way': {
            type: String,
            default: ''
        },
        cityOnly: {
            type: Boolean,
            default: false
        },
        // 是否显示全国
        isShowCountry: {
            type: Boolean,
            default: false
        },
    },
    data () {
        return {
            NodeData: [],
            item: {},
            map: {},
            
        }
    },
computed: {
        disabled1: function () { //是否禁用控件
            var result = false;
            if (!IsNullOrEmpty(this[this.props.disabled])) {
                result = this[this.props.disabled] == "true" ? true : false;
            }
            return result;
        },
        placeholder1: function () { //输入框占位文本
            var result = IsNullOrEmpty(this.placeholder) ? "" : this.placeholder;
            return result;
        },
        bindData: {
            get: function () {
                var parentList = [];
                if (!IsNullOrEmpty(this.value)) {
                    this.GetParent(this.value, this.NodeData, parentList);
                }
                parentList = parentList.reverse();
                this.stop = false;
                
                var that = this, fullName = "";
                parentList.forEach(function(item, index) {
                    fullName += ('-' + that.map[item]['cityname']);
                });
                this.$emit('update:cityname', fullName.substring(1));
                return parentList;
            },
            set: function (val) {
                var value = val.length > 0 ? val[val.length - 1] : "";
                this.$emit("input", value);
            }
        }
    },
    methods: {
        change:function(val){
            this.$emit("change",val);
        },
        GetParent: function (value, list, parentList) { //获取父级节点
            var that = this;
            $.each(list, function (i) {
                if (this[that.props.children]) {
                    if (this[that.props.value] == value) {
                        that.stop = true;
                        parentList.push(this[that.props.value]);
                        if(!this.changeOnSelect){
                            return false;
                        }
                    } else {
                        that.GetParent(value, this[that.props.children], parentList)
                    }

                    if (that.stop) {
                        parentList.push(this[that.props.value]);
                        return false;
                    }
                } else {
                    if (this[that.props.value] == value) {
                        that.stop = true;
                        parentList.push(this[that.props.value]);
                        return false;
                    }
                }
            })
        }
    },
    mounted: function() {
        if (this.resulttype && this.resulttype != "0" && !this.$listeners.getresult) {
            throw new Error('当级联选择器组件返回类型不为0时必须添加getresult函数');
        }
        var that = this;
        //获取城市数据
            if(that.$store.state.citylist.citylist.length != 0){
                if(that.cityOnly) {
                    that.$store.state.citylist.citylist = that.$store.state.citylist.citylist.filter(function(item) {
                        return /00$/.test(item.citycode);
                    })
                }
                that.NodeData = arrBuildTree(that.$store.state.citylist.citylist, 'parentcode', 'citycode');
                that.$store.state.citylist.citylist.forEach(function(item) {
                    that.map[item[that.props.value]] = item;
                });
            }else{
                that.$get('/bhtong/common/city/getarea',{
                    parentid:"all"
                },function(data){
                    if(that.cityOnly) {
                        data = data.filter(function(item) {
                            return /00$/.test(item.citycode);
                        })
                    }
                    that.NodeData = arrBuildTree(data, 'parentcode', 'citycode');
                    data.forEach(function(item) {
                        that.map[item[that.props.value]] = item;
                    });
                })
            }
            if(!that.isShowCountry && that.NodeData[0].cityname == '全国'){
                that.NodeData.shift()
            }

    }
}
