//
//
//
//
//
//

export default {
    props: {
        text: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: 'el-icon-info'
        },
        color: {
            type: String,
            default: '#67C23A'
        }
    },
    data () {
        return {

        }
    },
    methods: {

    },
    mounted: function() {

    }
}
