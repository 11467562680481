//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    components: { },
    props: {
        action:{
            type: String,
            default: '/bhtong/common/file/uploadFile'
        },
        fileobj:{
            type:Object,
            default:function(){
                return {}
            }
        },
        mubanShow:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return{
            fileList:[]
        }
    },
    computed: {

    },
    watch:{
        fileobj:{
            handler(n,o){
                if(n.contractFileName && n.contractFileUrl){
                    this.fileList.push({ name:n.contractFileName, url: n.contractFileUrl })
                }
            },
            deep:true,
            immediate:true
        } 
        
    },
    methods: {
        //点击打开文件
        handlePreview(file) {
            var val ='';
            if(!!file.url){
                val = file.url
            }else{
                val = file.response.tdata.fileUrl
            }
            window.open(val);
        },
        beforeUpload:function(file){
            let filesize = file.size / 1024 / 1024 
            if (filesize > 10) {
              this.$message.warning('上传文件大小不能超过 10MB!');
              return false
            }
        },
        //上传成功时
        uploadSuccess:function(response, file,fileList){
            this.$emit('setfile',response.tdata);
        },

        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 1 个文件，请移除当前文件后，在进行上传`);
        },
        //文件移除之前
        beforeRemove(file, fileList) {
            if(file && file.status === 'success'){
                return this.$confirm(`确定移除 ${ file.name }？`);
            }
        },
        //文件移除时
        handleRemove(file, fileList) {
            this.$emit('setfile','');
            this.fileList = []
        },
    },
    mounted:function(){

    }
}
